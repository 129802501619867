import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin'
import BookmarkIcon from '@material-ui/icons/Bookmark'

export const ConceptIcon = BookmarkIcon

export const ConceptList = (props) => (
<List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" sortable={false} />
      <TextField source="txt" sortable={false} />
      <EditButton basePath="/concepts" />
    </Datagrid>
  </List>
)

const ConceptTitle = ({ record }) => {
  return <span>Concept {record ? `"${record.txt}"` : ''}</span>
}

export const ConceptEdit = (props) => (
  <Edit title={<ConceptTitle />} {...props}>
    <SimpleForm redirect="edit">
      <TextInput disabled source="id" />
      <TextInput source="txt" fullWidth />
    </SimpleForm>
  </Edit>
)

export const ConceptCreate = (props) => (
  <Create title="Create a Concept" {...props}>
    <SimpleForm>
      <TextInput source="txt" />
    </SimpleForm>
  </Create>
)
