import httpClient, { API_URL } from './ApiClient'

/**
 * A custom React Admin DataProvider for CodeDoor. See https://marmelab.com/react-admin/DataProviders.html
 * for more info.
 */
export default {
  getList: (resource, params) => {
    const url = `${API_URL}/${resource}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: json.length,
    }))
  },

  getOne: (resource, params) =>
    httpClient(`${API_URL}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  update: (resource, params) =>
    httpClient(`${API_URL}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  create: (resource, params) =>
    httpClient(`${API_URL}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${API_URL}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  getMany: (resource, params) => {
    //
  },

  getManyReference: (resource, params) => {
    //
  },

  updateMany: (resource, params) => {
    //
  },

  deleteMany: (resource, params) => {
    //
  },
}
