import Amplify, { Auth } from 'aws-amplify'
import awsconfig from './../aws-exports'
import { hydrateSession, AUTHORIZATION_TOKEN_KEY, API_TOKEN_KEY } from './ApiClient'

/**
 * Set up the AWS Amplify authentication.
 */
export const setupAthentication = () => {
  Amplify.configure(awsconfig)
}

export default {
  /**
   * Log in a user by username and password.
   *
   * @param {object} data Login data
   */
  login: async ({ username, password }) => {
    await Auth.signIn(username, password)
    const { accessToken, idToken } = Auth.user.signInUserSession

    await hydrateSession(accessToken.jwtToken, idToken.jwtToken)
  },

  /**
   * Log out a user.
   */
  logout: async () => {
    await Auth.signOut()
    localStorage.removeItem(AUTHORIZATION_TOKEN_KEY)
    localStorage.removeItem(API_TOKEN_KEY)
  },

  /**
   * Check whether the user is authenticated.
   */
  checkAuth: () => localStorage.getItem(AUTHORIZATION_TOKEN_KEY)
    ? Promise.resolve()
    : Promise.reject(),

  /**
   * Hook into and handle an API error.
   */
  checkError: async error => {
    try {
      const { accessToken, idToken } = Auth.user.signInUserSession

      await hydrateSession(accessToken.jwtToken, idToken.jwtToken)
    } catch (error) {
      localStorage.removeItem(AUTHORIZATION_TOKEN_KEY)
      localStorage.removeItem(API_TOKEN_KEY)

      window.location.reload()
    }
  },

  getPermissions: params => Promise.resolve(),
}
