import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin'
import CreateIcon from '@material-ui/icons/Create'

export const ElementIcon = CreateIcon

export const ElementList = (props) => (
<List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" sortable={false} />
      <TextField source="txt" sortable={false} />
      <EditButton basePath="/elements" />
    </Datagrid>
  </List>
)

const ElementTitle = ({ record }) => {
  return <span>Element {record ? `"${record.txt}"` : ''}</span>
}

export const ElementEdit = (props) => (
  <Edit title={<ElementTitle />} {...props}>
    <SimpleForm redirect="edit">
      <TextInput disabled source="id" />
      <TextInput source="txt" fullWidth />
      <TextInput source="description" fullWidth />
      <TextInput source="regex_js" fullWidth />
    </SimpleForm>
  </Edit>
)

export const ElementCreate = (props) => (
  <Create title="Create a Element" {...props}>
    <SimpleForm>
      <TextInput source="txt" />
    </SimpleForm>
  </Create>
)
