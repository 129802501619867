import { fetchUtils } from 'react-admin'

export const API_URL = process.env.REACT_APP_API_URL
export const AUTHORIZATION_TOKEN_KEY = 'auth_token'
export const API_TOKEN_KEY = 'api_token'

/**
 * Initialize an HTTP Client.
 *
 * @param {string} url
 * @param {object} options
 */
const httpClient = (url, options = {}) => {
  if (! options.headers) {
      options.headers = new Headers();
  }

  // Set default "Accept"-header
  if (! options.headers.has('Accept')) {
    options.headers.set('Accept', 'application/json');
  }

  // Set API & Authorization Tokens
  if (! options.headers.has('Authorization')) {
    options.headers.set('Authorization', localStorage.getItem(AUTHORIZATION_TOKEN_KEY));
    options.headers.set('X-Api-Token', localStorage.getItem(API_TOKEN_KEY));
  }

  // Set the locale header.
  const locale = localStorage.getItem('locale') || 'en'
  options.headers.set('Accept-Language', locale);

  return fetchUtils.fetchJson(url, options);
}

/**
 * Hydrate the session against the CodeDoor API.
 *
 * @param {string} authorizationToken
 * @param {string} identityToken
 */
export const hydrateSession = async (authorizationToken, identityToken) => {
  const headers = new Headers({
    Authorization: authorizationToken,
    Identity: identityToken,
  })

  try {
    const { result } = (await httpClient(`${API_URL}/aws-migration/signIn.php`, {
      method: 'POST',
      headers,
    })).json

    localStorage.setItem(AUTHORIZATION_TOKEN_KEY, authorizationToken)
    localStorage.setItem(API_TOKEN_KEY, result.apiToken)

    return result
  } catch (error) {
    throw error
  }
}

export default httpClient
