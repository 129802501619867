import React from 'react'
import RichTextInput from 'ra-input-rich-text'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin'
import AccountTreeIcon from '@material-ui/icons/AccountTree'

export const TopicIcon = AccountTreeIcon

export const TopicList = (props) => (
<List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" sortable={false} />
      <TextField source="txt" sortable={false} />
      <EditButton basePath="/topics" />
    </Datagrid>
  </List>
)

const TopicTitle = ({ record }) => {
  return <span>Topic {record ? `"${record.txt}"` : ''}</span>
}

export const TopicEdit = (props) => (
  <Edit title={<TopicTitle />} {...props}>
    <SimpleForm redirect="edit">
      <TextInput disabled source="id" />
      <TextInput source="txt" fullWidth />
      <TextInput source="badge" fullWidth />
      <TextInput source="duration" fullWidth />
      <RichTextInput source="project" fullWidth />
    </SimpleForm>
  </Edit>
)

export const TopicCreate = (props) => (
  <Create title="Create a Topic" {...props}>
    <SimpleForm>
      <TextInput source="txt" />
    </SimpleForm>
  </Create>
)
