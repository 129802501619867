export default {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id: 'us-east-2:794bead0-e472-447c-9791-76755915ca6a',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_40two7WWa',
  aws_user_pools_web_client_id: '70tvj5o9knj5mjap9p5bdd9s4s',
  oauth: {
    domain: 'cddramplifya5169101-a5169101-local.auth.us-east-2.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
}
