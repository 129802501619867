import React, { useState } from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import { useRefresh } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    activeMenuButton: {
      color: 'rgba(255, 255, 255, 0.8) !important',
      border: '1px solid rgba(0, 0, 0, 0.3) !important',
    },
  }),
  { name: 'CodeDoorStyle' }
)

const LocaleButton = ({ current, locale, onChange, ...props }) => {
  const isCurrent = current === locale
  const classes = useStyles(props);

  return <Button
    {...props}
    onClick={() => onChange(locale)}
    color="inherit"
    size="small"
    variant={isCurrent ? 'contained' : 'outlined' }
    disabled={isCurrent}
    className={isCurrent
      ? classNames(classes.activeMenuButton, props.className)
      : props.className
    }
  >{locale.toUpperCase()}</Button>
}

const LocaleSwitcher = (props) => {
  const classes = useStyles(props);
  const refresh = useRefresh()
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en')
  const update = locale => {
    localStorage.setItem('locale', locale)
    setLocale(locale)
    refresh()
  }

  return (
    <div>
      <ButtonGroup
        size="small"
        color="inherit"
        className={classNames(classes.menuButton)}
      >
        <LocaleButton current={locale} locale="en" onChange={update} />
        <LocaleButton current={locale} locale="de" onChange={update} />
        <LocaleButton current={locale} locale="fr" onChange={update} />
        <LocaleButton current={locale} locale="es" onChange={update} />
        <LocaleButton current={locale} locale="jp" onChange={update} />
      </ButtonGroup>
    </div>
  )
}

export default LocaleSwitcher
