import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'

export const ProjectIcon = CollectionsBookmarkIcon

export const ProjectList = (props) => (
<List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" sortable={false} />
      <TextField source="title" sortable={false} />
      <EditButton basePath="/projects" />
    </Datagrid>
  </List>
)

const ProjectTitle = ({ record }) => {
  return <span>Project {record ? `"${record.title}"` : ''}</span>
}

export const ProjectEdit = (props) => (
  <Edit title={<ProjectTitle />} {...props}>
    <SimpleForm redirect="edit">
      <TextInput disabled source="id" />
      <TextInput source="title" fullWidth />
      <TextInput source="description" multiline fullWidth />
      <TextInput source="delivery" multiline fullWidth />
      <TextInput source="coordination" multiline fullWidth />
      <TextInput source="documentation" multiline fullWidth />
      <TextInput source="presentation" multiline fullWidth />
      <TextInput source="empathy" multiline fullWidth />
      <TextInput source="bestpractice" multiline fullWidth />
    </SimpleForm>
  </Edit>
)

export const ProjectCreate = (props) => (
  <Create title="Create a Project" {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline fullWidth />
    </SimpleForm>
  </Create>
)
