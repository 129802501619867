import React from 'react'
import { render } from 'react-dom'
import { Admin, Resource } from 'react-admin'
import AuthenticationProvider, { setupAthentication } from './api/AuthenticationProvider'
import CodeDoorLayout from './ui/CodeDoorLayout'
import CodeDoorProvider from './api/CodeDoorProvider'
import { ProjectList, ProjectEdit, ProjectCreate, ProjectIcon } from './models/projects'
import { TopicList, TopicEdit, TopicIcon } from './models/topics'
import { ResourceList, ResourceEdit, ResourceCreate, ResourceIcon } from './models/resources'
import { ConceptList, ConceptEdit, ConceptIcon } from './models/concepts'
import { ElementList, ElementEdit, ElementIcon } from './models/elements'

setupAthentication()

render(
  <Admin
    authProvider={AuthenticationProvider}
    dataProvider={CodeDoorProvider}
    layout={CodeDoorLayout}
  >
    <Resource
      name="projects"
      list={ProjectList} edit={ProjectEdit} create={ProjectCreate}
      icon={ProjectIcon}
    />
    <Resource
      name="topics"
      list={TopicList} edit={TopicEdit}
      icon={TopicIcon}
    />
    <Resource
      name="concepts"
      list={ConceptList} edit={ConceptEdit}
      icon={ConceptIcon}
    />
    <Resource
      name="elements"
      list={ElementList} edit={ElementEdit}
      icon={ElementIcon}
    />
    <Resource
      name="resources"
      list={ResourceList} edit={ResourceEdit} create={ResourceCreate}
      icon={ResourceIcon}
    />
  </Admin>,
  document.getElementById('root')
)
