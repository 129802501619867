import React from 'react'
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
} from 'react-admin'
import LinkIcon from '@material-ui/icons/Link'

export const ResourceIcon = LinkIcon

export const ResourceList = (props) => (
<List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid isRowSelectable={() => false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <EditButton basePath="/resources" />
    </Datagrid>
  </List>
)

const ResourceTitle = ({ record }) => {
  return <span>Resource {record ? `"${record.name}"` : ''}</span>
}

export const ResourceEdit = (props) => (
  <Edit title={<ResourceTitle />} {...props}>
    <SimpleForm redirect="edit">
      <TextInput disabled source="id" />
      <TextInput source="name" fullWidth />
      <TextInput source="url" fullWidth />
      <TextInput source="topic" fullWidth />
    </SimpleForm>
  </Edit>
)

export const ResourceCreate = (props) => (
  <Create title="Create a Resource" {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
)
